import React, { useState, useEffect } from 'react';
import versionData from './versionData'; // Adjust the path to your versionData file
import DOMPurify from 'dompurify';

// Import your icons
import overworldIcon from './icons/grass.webp'; // path to your Overworld icon
import netherIcon from './icons/nether.webp'; // path to your Nether icon
import endIcon from './icons/end.webp'; // path to your End icon

const WorldSizeCalculator = () => {
    const [selectedVersion, setSelectedVersion] = useState('1.20.1');
    const [radius, setRadius] = useState({
        overworld: 0,
        nether: 0,
        end: 0
    });
    const [worldSizes, setWorldSizes] = useState({
        overworld: 0,
        nether: 0,
        end: 0
    });

    const updateURL = () => {
        const queryParams = new URLSearchParams();
        queryParams.set('v', selectedVersion);
        queryParams.set('o', radius.overworld);
        queryParams.set('n', radius.nether);
        queryParams.set('e', radius.end);

        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
    };

    useEffect(() => {
        const parseQueryParams = () => {
            const params = new URLSearchParams(window.location.search);
            const version = DOMPurify.sanitize(params.get('v'));
            const overworldRadius = DOMPurify.sanitize(params.get('o'));
            const netherRadius = DOMPurify.sanitize(params.get('n'));
            const endRadius = DOMPurify.sanitize(params.get('e'));

            if (version && versionData[version]) {
                setSelectedVersion(version);
            }

            setRadius(prevRadius => ({
                ...prevRadius,
                overworld: overworldRadius && !isNaN(overworldRadius) ? Number(overworldRadius) : prevRadius.overworld,
                nether: netherRadius && !isNaN(netherRadius) ? Number(netherRadius) : prevRadius.nether,
                end: endRadius && !isNaN(endRadius) ? Number(endRadius) : prevRadius.end
            }));
        };

        parseQueryParams();
    }, []);

    /*const [totalSize, setTotalSize] = useState(0);*/

    const handleVersionChange = (event) => {
        setSelectedVersion(event.target.value);
    };

    const calculateSize = (version, radius, type) => {
        const chunks = radius / 16;
        const chunksSquared = chunks * chunks;
        const factor = versionData[version][type];
        return ((chunksSquared * factor) / 1024 / 1024) * 4;
    };

    /*const calculateTotalSize = () => {
        const overworldSize = calculateSize(selectedVersion, radius.overworld, 'overworld');
        const netherSize = calculateSize(selectedVersion, radius.nether, 'nether');
        const endSize = calculateSize(selectedVersion, radius.end, 'end');
        return (overworldSize + netherSize + endSize).toFixed(2);
    };*/

    useEffect(() => {
        const newWorldSizes = {
            overworld: calculateSize(selectedVersion, radius.overworld, 'overworld').toFixed(2),
            nether: calculateSize(selectedVersion, radius.nether, 'nether').toFixed(2),
            end: calculateSize(selectedVersion, radius.end, 'end').toFixed(2),
        };
        setWorldSizes(newWorldSizes);
        /*setTotalSize(calculateTotalSize());*/
        updateURL();
        // eslint-disable-next-line
    }, [selectedVersion, radius]);

    const handleSliderChange = (type) => (event) => {
        setRadius((prevRadius) => ({
            ...prevRadius,
            [type]: Number(event.target.value),
        }));
    };

    return (
        <div className="world-size-calculator">
            {/* Version Selector */}
            <div className="version-selector-container">
                <select
                    id="version-select"
                    value={selectedVersion}
                    onChange={handleVersionChange}
                    className="version-selector"
                >
                    {Object.keys(versionData).reverse().map((version) => (
                        <option key={version} value={version}>
                            {version}
                        </option>
                    ))}
                </select>
            </div>

            {/* Sliders */}
            <div className="slider-container">
                <img src={overworldIcon} alt="Overworld" className="slider-icon" />
                {/* Overworld Slider */}
                <input
                    className="overworld-slider"
                    type="range"
                    min="0"
                    max="100000"
                    step="500"
                    value={radius.overworld}
                    onChange={handleSliderChange('overworld')}
                />
                <span className="slider-value">{radius.overworld}</span>
            </div>

            <div className="slider-container">
                <img src={netherIcon} alt="Nether" className="slider-icon" />
                {/* Nether Slider */}
                <input
                    className="nether-slider"
                    type="range"
                    min="0"
                    max="100000"
                    step="500"
                    value={radius.nether}
                    onChange={handleSliderChange('nether')}
                />
                <span className="slider-value">{radius.nether}</span>
            </div>

            <div className="slider-container">
                <img src={endIcon} alt="End" className="slider-icon" />
                {/* End Slider */}
                <input
                    className="end-slider"
                    type="range"
                    min="0"
                    max="100000"
                    step="500"
                    value={radius.end}
                    onChange={handleSliderChange('end')}
                />
                <span className="slider-value">{radius.end}</span>
            </div>

            <div className="slider-container sizes">
                <p style={{"color": "#4CAF50"}}>{worldSizes.overworld}GB</p><p style={{"color": "#703131"}}>&nbsp;&nbsp;{worldSizes.nether}GB</p>&nbsp;&nbsp;<p style={{"color": "#dae09c"}}>{worldSizes.end}GB</p>
            </div>
        </div>
    );
};

export default WorldSizeCalculator;